var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-items-center",
    class: {
      'TabTitle-border': _vm.border
    }
  }, [_vm.$slots.actions && _vm.actionPosition === 'left' ? _c('div', {
    staticClass: "border-right mr-3 pr-1"
  }, [_vm._t("actions", function () {
    return [_c('a', {
      staticClass: "btn btn-link",
      on: {
        "click": function ($event) {
          return _vm.$router.go(-1);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-arrow-left mr-1"
    })])];
  })], 2) : _vm._e(), _c('div', {
    staticClass: "d-flex align-items-center flex-grow-1"
  }, [_c('div', [_vm.icon ? _c('i', {
    staticClass: "fa-duotone duotone-etainabl fa-2x fa-fw mr-3",
    class: _vm.icon
  }) : _vm._e()]), _c('div', [_c('h2', {
    staticClass: "mb-0"
  }, [_vm._t("default", function () {
    return [_vm._v("Page Title")];
  })], 2), _c('p', {
    staticClass: "mb-0 text-muted"
  }, [_vm._t("sub-title")], 2)])]), _vm.$slots.actions && _vm.actionPosition === 'right' ? _c('div', [_vm._t("actions", function () {
    return [_c('a', {
      staticClass: "btn btn-link",
      on: {
        "click": function ($event) {
          return _vm.$router.go(-1);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-arrow-left mr-1"
    })])];
  })], 2) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._b({
    staticClass: "form-group"
  }, 'div', _vm.$attrs, false), [_vm.label ? _c('label', {
    class: {
      'mb-0': _vm.descriptionPosition === 'top'
    },
    attrs: {
      "for": _vm.id
    }
  }, [_vm.icon ? _c('i', {
    class: `fa ${_vm.icon} mr-2`
  }) : _vm._e(), _vm._v(_vm._s(_vm.label))]) : _vm._e(), _vm.description && _vm.descriptionPosition === 'top' ? _c('div', {
    staticClass: "text-gray-dark font-w600 font-size-sm mb-2"
  }, [_vm._v(_vm._s(_vm.description))]) : _vm._e(), _c('FormItem', _vm._b({
    attrs: {
      "value": _vm.value
    },
    on: {
      "input": _vm.onInput,
      "error": _vm.onInputError,
      "itemAdded": _vm.onItemAdded,
      "itemRemoved": _vm.onItemRemoved
    }
  }, 'FormItem', _vm.$props, false)), _vm.description && _vm.descriptionPosition === 'bottom' ? _c('div', {
    staticClass: "text-gray-dark font-w600 font-size-sm mt-1"
  }, [_vm._v(_vm._s(_vm.description))]) : _vm._e(), _c('FormError', {
    attrs: {
      "id": _vm.id,
      "error": _vm.errorMessage
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
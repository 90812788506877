var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "data-cy": "user-edit-form"
    }
  }, [_c('TabTitle', {
    staticClass: "mb-4",
    attrs: {
      "icon": "fa-user-gear"
    },
    scopedSlots: _vm._u([{
      key: "sub-title",
      fn: function () {
        return [_vm._v("Edit and update the user details.")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('router-link', {
          staticClass: "btn btn-link",
          attrs: {
            "to": {
              path: `/company/users`
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-arrow-left mr-1"
        })])];
      },
      proxy: true
    }])
  }, [[_vm._v("Edit User")]], 2), _c('div', {
    staticClass: "block-content"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.errorAction.updateUser,
      expression: "errorAction.updateUser"
    }],
    staticClass: "alert alert-danger"
  }, [_vm._v(_vm._s(_vm.errorAction.updateUser))]), _c('div', {
    staticClass: "block block-rounded"
  }, [_c('div', {
    staticClass: "block-content"
  }, [_vm.loadingAction.getUser || !Object.keys(_vm.form).length ? _c('div', {
    staticClass: "d-flex justify-content-center mb-5"
  }, [_vm._m(0)]) : _c('div', [_c('CompanyUserForm', {
    attrs: {
      "error": _vm.validationErrors,
      "disabled": _vm.loadingAction.update
    },
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }), _c('div', {
    staticClass: "text-right pb-4"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "submit",
      "disabled": _vm.loadingAction.updateUser
    },
    on: {
      "click": _vm.onEditUser
    }
  }, [_vm.loadingAction.updateUser ? _c('span', [_c('i', {
    staticClass: "fa fa-spin fa-spinner mr-1"
  }), _vm._v(" Updating...")]) : _c('span', [_c('i', {
    staticClass: "fa fa-check-circle mr-1"
  }), _vm._v(" Update User")])])])], 1)])])])], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "spinner-border spinner-lg text-secondary mb-4",
    attrs: {
      "role": "status"
    }
  }, [_c('span', {
    staticClass: "sr-only"
  }, [_vm._v("Loading user details...")])]), _c('div', {
    staticClass: "font-w600"
  }, [_vm._v("Loading user details...")])]);

}]

export { render, staticRenderFns }